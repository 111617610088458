import { render, staticRenderFns } from "./ViewMonoPoint.vue?vue&type=template&id=096a4a70&scoped=true"
import script from "./ViewMonoPoint.vue?vue&type=script&lang=js"
export * from "./ViewMonoPoint.vue?vue&type=script&lang=js"
import style0 from "./ViewMonoPoint.vue?vue&type=style&index=0&id=096a4a70&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "096a4a70",
  null
  
)

/* custom blocks */
import block0 from "./ViewMonoPoint.vue?vue&type=custom&index=0&blockType=i18n&lang=json"
if (typeof block0 === 'function') block0(component)

export default component.exports