<template>
	<section class="register">
		<page-title :title="$t('title')" :breadcrumbs="items"></page-title>
		<v-container>
			<v-row>
				<v-col cols="12" lg="8" xl="6" class="mx-auto">
					<v-form ref="formUser" @submit.prevent="createUser">
						<v-stepper v-model="page" class="elevation-0">
							<v-stepper-header class="elevation-0">
								<v-stepper-step :complete="page > 1" step="1" editable color="yellow" :rules="premierOngletRules">
									{{ $t('user.info') }}
								</v-stepper-step>

								<v-divider></v-divider>

								<v-stepper-step v-if="!user_parent_id" :complete="page > 2" step="2" editable color="yellow">
									{{ $t('address.billing') }}
								</v-stepper-step>

								<v-divider v-if="!user_parent_id"></v-divider>

								<v-stepper-step :step="user_parent_id ? 2 : 3" editable color="yellow">
									{{ $t('form.password') }}
								</v-stepper-step>
							</v-stepper-header>

							<v-stepper-items>
								<v-stepper-content step="1">
									<v-container>
										<v-row>
											<v-col cols="12">
												<v-text-field
													v-model="code_compta"
													:label="$t('user.code_compta')"
													outlined
													class="border-shaped"
													:rules="notBlankRule"
												></v-text-field>
												<v-text-field
													v-model="code_masterprint"
													:label="$t('user.code_masterprint')"
													outlined
													class="border-shaped"
													:rules="notBlankRule"
												></v-text-field>
												<v-text-field
													v-model="real_name"
													:label="$t('user.publishername')"
													outlined
													class="border-shaped"
													:rules="notBlankRule"
												>
												</v-text-field>
												<v-text-field
													v-model="username"
													:label="$t('user.username')"
													outlined
													class="border-shaped"
													:rules="notBlankRule"
												></v-text-field>
												<v-text-field
													v-model="email"
													:label="$t('global.email')"
													outlined
													class="border-shaped"
													:rules="notBlankRule"
												></v-text-field>
												<vue-tel-input-vuetify
													v-model="phone"
													:label="$t('global.phone')"
													:preferred-countries="['FR', 'BE', 'CH', 'CA']"
													outlined
													class="border-shaped-tel"
													mode="international"
												></vue-tel-input-vuetify>
												<v-autocomplete
													v-model="user_parent_id"
													:items="user_items"
													:label="$t('global.parent')"
													class="border-shaped"
													outlined
													clearable
													:item-text="(user) => user.username + ' - ' + user.email"
													item-value="id"
												></v-autocomplete>
												<v-select
													v-model="role"
													:items="roles"
													item-text="name"
													:label="$t('user.role')"
													class="border-shaped"
													outlined
												></v-select>
											</v-col>
											<v-col cols="12" align="right">
												<v-btn class="border-shaped yellow--bg" large depressed @click="++page">
													{{ $t('global.next') }}
												</v-btn>
											</v-col>
										</v-row>
									</v-container>
								</v-stepper-content>

								<v-stepper-content v-if="!user_parent_id" step="2">
									<v-container>
										<v-row>
											<v-col cols="12">
												<v-select
													v-model="address_type"
													:label="$t('address.type')"
													outlined
													class="border-shaped"
													:items="address_types_items"
													:item-text="(item) => ($i18n.locale === 'fr' ? item.name : item.en_name)"
													item-value="id"
													required
													:rules="address_typesRules"
												></v-select>
												<v-text-field
													v-model="address.contact"
													:label="$t('address.contact')"
													required
													prepend-inner-icon="mdi-account"
													:rules="contact_rules"
													outlined
													class="border-shaped"
												></v-text-field>
												<v-text-field
													v-if="address_type > 1"
													v-model="address.raison_sociale"
													:label="$t('address.raison_sociale')"
													:rules="raison_sociale_rules"
													prepend-inner-icon="mdi-domain"
													outlined
													class="border-shaped"
												></v-text-field>
												<v-text-field
													v-model="address.email"
													:label="$t('global.email')"
													required
													prepend-inner-icon="mdi-at"
													:rules="email_rules"
													outlined
													class="border-shaped"
												></v-text-field>
												<v-text-field
													v-model="address.address"
													:label="$t('address.address')"
													required
													prepend-inner-icon="mdi-map-marker"
													type="email"
													:rules="address_rules"
													outlined
													class="border-shaped"
												></v-text-field>
												<v-text-field
													v-model="address.complement"
													:label="$t('address.complement')"
													prepend-inner-icon="mdi-comment-text-outline"
													outlined
													class="border-shaped"
												></v-text-field>
												<v-text-field
													v-model="address.code_postal"
													:label="$t('address.postal_code')"
													required
													prepend-inner-icon="mdi-email-outline"
													:rules="code_postal_rules"
													outlined
													class="border-shaped"
												></v-text-field>
												<v-text-field
													v-model="address.city"
													:label="$t('address.city')"
													required
													prepend-inner-icon="mdi-city"
													:rules="city_rules"
													outlined
													class="border-shaped"
												></v-text-field>
												<v-select
													v-model="address.country"
													:items="countries"
													item-text="name"
													item-value="iso"
													:label="$t('address.country')"
													return-object
													outlined
													class="border-shaped"
												>
													<template #selection="{ item }">
														<v-list-item-icon>
															<CountryFlag :country="item.iso" rounded class="mt-n2" />
														</v-list-item-icon>
														<v-list-item-content>
															<v-list-item-title>{{ item.name }}</v-list-item-title>
														</v-list-item-content>
													</template>
													<template #item="{ item }">
														<v-list-item-icon>
															<CountryFlag :country="item.iso" rounded class="mt-n2" />
														</v-list-item-icon>
														<v-list-item-content>
															<v-list-item-title>{{ item.name }}</v-list-item-title>
														</v-list-item-content>
													</template>
												</v-select>
												<vue-tel-input-vuetify
													v-model="address.phone_mobile"
													:label="$t('address.phone.mobile')"
													:preferred-countries="['FR', 'BE', 'CH', 'CA']"
													outlined
													class="border-shaped-tel"
													mode="international"
													:rules="phone_mobile_rules"
													@validate="validPhoneMobile"
												></vue-tel-input-vuetify>
												<vue-tel-input-vuetify
													v-model="address.phone_fixe"
													:label="$t('address.phone.fix')"
													:preferred-countries="['FR', 'BE', 'CH', 'CA']"
													outlined
													class="border-shaped-tel"
													mode="international"
													:rules="phone_fixe_rules"
													@validate="validPhoneFix"
												></vue-tel-input-vuetify>
												<v-textarea v-model="address.comment" :label="$t('address.comment')" outlined counter></v-textarea>
											</v-col>
											<v-col cols="12" align="right">
												<v-btn class="border-shaped yellow--bg mr-1" large depressed @click="--page">
													{{ $t('global.previous') }}
												</v-btn>
												<v-btn v-btn class="border-shaped yellow--bg" large depressed @click="++page">
													{{ $t('global.next') }}
												</v-btn>
											</v-col>
										</v-row>
									</v-container>
								</v-stepper-content>

								<v-stepper-content :step="user_parent_id ? 2 : 3">
									<v-container>
										<v-row>
											<v-col cols="12">
												<v-text-field
													v-model="password"
													:label="$t('form.password')"
													outlined
													class="border-shaped"
													type="password"
													:rules="password_rules"
												>
													<template #label></template>
												</v-text-field>
												<v-text-field
													v-model="confirm_password"
													:label="$t('form.confirm_password')"
													outlined
													class="border-shaped"
													type="password"
													:rules="confirm_password_rules"
												></v-text-field>
												<password v-model="password" :strength-meter-only="true" @feedback="showFeedback" />
												<v-list dense>
													<v-list-item v-for="(item, i) in feedback" :key="i">
														<v-list-item-icon>
															<v-icon>mdi-information</v-icon>
														</v-list-item-icon>
														{{ item }}
													</v-list-item>
												</v-list>
											</v-col>
											<v-col cols="12" align="right">
												<v-btn class="border-shaped yellow--bg mr-1" large depressed @click="--page">
													{{ $t('global.previous') }}
												</v-btn>
												<v-btn class="border-shaped yellow--bg" large depressed type="submit" :loading="loading">
													{{ $t('form.submit') }}
												</v-btn>
											</v-col>
										</v-row>
									</v-container>
								</v-stepper-content>
							</v-stepper-items>
						</v-stepper>
					</v-form>
				</v-col>
			</v-row>
		</v-container>
	</section>
</template>

<script lang="js">
	import Password from 'vue-password-strength-meter';
	import axios from 'axios';
	import { EventBus } from '@/utils';
	import PageTitle from '@/components/info/PageTitle';

	export default {
		name: 'Register',
		components: { PageTitle, Password },
		props: [],
		data() {
			return {
				submitted: false,
				loading: false,
				page: 1,
				code_compta: '',
				code_masterprint: '',
				real_name: '',
				username: '',
				email: '',
				phone: '',
				role: 1,
				roles: [
					{
						name: 'User',
						value: 1,
					},
					{
						name: 'Admin',
						value: 2,
					},
				],
				user_parent_id: null,
				user_items: [],
				address_type: null,
				address: {
					type: { id: 1 },
					contact: '',
					raison_sociale: '',
					email: '',
					address: '',
					complement: '',
					code_postal: '',
					city: '',
					country: { state: 'France', iso: 'FR' },
					phone_mobile: '',
					phone_fixe: '',
					comment: '',
				},
				password: '',
				confirm_password: '',
				contact_rules: [(v) => !!v || this.$t('form.required.address.contact')],
				raison_sociale_rules: [(v) => !!v || this.$t('form.required.address.raison_sociale')],
				email_rules: [
					(v) => !!v || this.$t('form.required.address.email'),
					(v) => /.+@.+\..+/.test(v) || this.$t('form.invalid.email'),
				],
				address_rules: [(v) => !!v || this.$t('form.required.address.address')],
				code_postal_rules: [(v) => !!v || this.$t('form.required.address.postal_code')],
				city_rules: [(v) => !!v || this.$t('form.required.address.city')],
				isPhoneMobileValid: null,
				phone_mobile_rules: [
					() =>
						!!this.address.phone_mobile || !!this.address.phone_fixe || this.$t('form.required.address.phone'),
					() =>
						(!this.address.phone_mobile && !!this.address.phone_fixe) ||
						this.isPhoneMobileValid ||
						this.$t('form.invalid.address.phone.mobile'),
				],
				isPhoneFixValid: null,
				phone_fixe_rules: [
					() =>
						!!this.address.phone_mobile || !!this.address.phone_fixe || this.$t('form.required.address.phone'),
					() =>
						(!this.address.phone_fixe && !!this.address.phone_mobile) ||
						this.isPhoneFixValid ||
						this.$t('form.invalid.address.phone.fix'),
				],
				countries: [
					{ name: 'France', iso: 'FR' },
					{ name: 'Belgique', iso: 'BE' },
					{ name: 'Suisse', iso: 'CH' },
				],
				types: [
					{ label: 'Particulier', value: 'particulier' },
					{ label: 'Professionelle', value: 'professionelle' },
					{ label: 'Distributeur', value: 'distributeur' },
					{ label: 'Interne', value: 'interne' },
				],
				address_types_items: [],
				address_typesRules: [(v) => v !== null || this.$t('form.required.address.type')],
				feedback: [],
				password_rules: [
					(v) => !!v || this.$t('form.required.password'),
					(v) => v.length > 0 || this.$t('form.required.password'),
				],
				confirm_password_rules: [
					(v) => !!v || this.$t('form.required.password'),
					(v) => v.length > 0 || this.$t('form.required.password'),
					() => this.password === this.confirm_password || this.$t('form.passwords_dont_match'),
				],
				notBlankRule: [
					(v) => !!v || this.$t('form.required.required'),
					(v) => v.length > 0 || this.$t('form.required.required'),
				],
				premierOngletRules: [
					() => {
						if (!this.submitted) {
							return true;
						}
						return !(
							!(this.code_compta.trim().length > 0) ||
							!(this.code_masterprint.trim().length > 0) ||
							!(this.real_name.trim().length > 0) ||
							!(this.username.trim().length > 0) ||
							(!(this.email.trim().length > 0) && /.+@.+\..+/.test(this.email.trim())) ||
							this.$t('form.invalid.email') ||
							!(this.phone.trim().length > 0)
						);
					},
				],
			};
		},
		computed: {
			items: function () {
				return [
					{
						text: 'Rapidbook',
						disabled: false,
						to: '/',
					},
					{
						text: this.$t('menu.register'),
						disabled: true,
						to: '',
					},
				];
			},
		},
		mounted() {
			this.getUsers();
			this.getAddressType();
			this.getCountries();
		},
		methods: {
			validPhoneMobile(value) {
				this.isPhoneMobileValid = value.isValid;
			},
			validPhoneFix(value) {
				this.isPhoneFixValid = value.isValid;
			},
			showFeedback({ suggestions }) {
				this.feedback = suggestions;
			},
			getCountries() {
				axios
					.get(process.env.VUE_APP_API_URL + '/country', {
						headers: {
							Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
						},
					})
					.then((response) => {
						this.countries = response.data.countries;
						this.country = this.countries[0];
					});
			},
			getUsers() {
				axios
					.get(process.env.VUE_APP_API_URL + '/admin/users', {
						headers: {
							Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
						},
					})
					.then((response) => {
						this.user_items = response.data.items;
					})
					.catch(() => { });
			},
			getAddressType() {
				axios
					.get(process.env.VUE_APP_API_URL + '/address_type', {
						headers: {
							Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
						},
					})
					.then((r) => {
						this.address_types_items = r.data.address_types;
						!this.address_type ? (this.address_type = this.address_types_items[0].id) : null;
					})
					.catch(() => {
						EventBus.$emit('notif', this.$t('global.error'), 'error');
					});
			},
			createUser() {
				this.submitted = true;
				this.loading = true;
				if (this.$refs.formUser.validate()) {
					axios
						.post(
							process.env.VUE_APP_API_URL + '/admin/users/register',
							{
								code_compta: this.code_compta,
								code_masterprint: this.code_masterprint,
								real_name: this.real_name,
								username: this.username,
								email: this.email,
								phone: this.phone,
								address: this.address.address,
								email_address: this.address.email,
								code_postal: this.address.code_postal,
								city: this.address.city,
								phone_fix_address: this.address.phone_fixe,
								phone_mobile_address: this.address.phone_mobile,
								country: this.address.country.iso,
								comment_address: this.address.comment,
								complement: this.address.complement,
								raison_sociale: this.address.raison_sociale,
								contact: this.address.contact,
								address_type: this.address_type,
								password: this.password,
								confirm_password: this.confirm_password,
								user_parent_id: this.user_parent_id,
								role: this.role,
							},
							{
								headers: {
									Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
								},
							}
						)
						.then(() => {
							EventBus.$emit('notif', this.$t('user.created'), 'success');
							this.submitted = false;
							this.code_compta = '';
							this.code_masterprint = '';
							this.real_name = '';
							this.username = '';
							this.phone = '';
							(this.address = {
								type: { label: 'Particulier', value: 'particulier' },
								contact: '',
								raison_sociale: '',
								email: '',
								address: '',
								complement: '',
								code_postal: '',
								city: '',
								country: { state: 'France', iso: 'FR' },
								phone_mobile: '',
								phone_fixe: '',
								comment: '',
							}),
								(this.password = '');
							this.confirm_password = '';
							this.user_parent_id = null;
							this.$refs.formUser.reset();
							this.page = 1;
							this.dialog = false;
							this.loading = false;
						})
						.catch((e) => {
							EventBus.$emit('notif', e.response.data.message || this.$t('global.error'), 'error');
							this.loading = false;
						});
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	.register {
		min-height: calc(100vh - 64px);
	}
</style>

<i18n lang="json">
{
	"fr": {
		"title": "Inscrire Client"
	},
	"en": {
		"title": "Register Client"
	}
}
</i18n>
