<template>
	<section class="simulation">
		<!-- dialog des prix -->
		<v-dialog v-model="dialog" max-width="800" class="border-shaped">
			<v-sheet class="border-shaped" elevation="24">
				<v-list-item>
					<v-list-item-content>
						<v-list-item-title class="text-h5 text-lg-h4">
							{{ $t('title') }}
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-divider class="mb-4"></v-divider>
				<v-list dense color="transparent">
					<v-list-item class="mt-n5">
						<v-list-item-title>{{ $t('order.price.production') }} :</v-list-item-title>
						<v-list-item-action>
							<v-list-item-action-text>
								{{ prix_fabrication >= 0 ? prix_fabrication.toFixed(2) + '€' : $t('unavailable') }}
							</v-list-item-action-text>
						</v-list-item-action>
					</v-list-item>
					<v-list-item class="mt-n5">
						<v-list-item-title>{{ $t('order.price.packaging') }} :</v-list-item-title>
						<v-list-item-action>
							<v-list-item-action-text>
								{{ prix_emballage >= 0 ? prix_emballage.toFixed(2) + '€' : $t('unavailable') }}
							</v-list-item-action-text>
						</v-list-item-action>
					</v-list-item>
					<v-list-item class="mt-n5">
						<v-list-item-title>{{ $t('order.price.shipping') }} :</v-list-item-title>
						<v-list-item-action>
							<v-list-item-action-text>
								{{ prix_livraison >= 0 ? prix_livraison.toFixed(2) + '€' : $t('unavailable') }}
							</v-list-item-action-text>
						</v-list-item-action>
					</v-list-item>
					<v-list-item class="mt-n5">
						<v-list-item-title>{{ $t('order.price.ht') }} :</v-list-item-title>
						<v-list-item-action>
							<v-list-item-action-text>{{ prix_ht.toFixed(2) }}€</v-list-item-action-text>
						</v-list-item-action>
					</v-list-item>
					<v-list-item class="mt-n5">
						<v-list-item-title>{{ $t('order.price.ttc') }}:</v-list-item-title>
						<v-list-item-action>
							<v-list-item-action-text>{{ prix_ttc.toFixed(2) }}€</v-list-item-action-text>
						</v-list-item-action>
					</v-list-item>
					<v-list-item class="mt-5">
						<v-list-item-title></v-list-item-title>
						<v-list-item-action>
							<v-list-item-action-text class="d-flex gap-2 mr-4">
								<v-btn depressed class="yellow--bg border-shaped" @click="getFacture">
									<v-icon>mdi-file-pdf-box</v-icon>
								</v-btn>
								<v-btn depressed class="yellow--bg border-shaped" @click="save">
									<v-icon>mdi-content-save-outline</v-icon>
								</v-btn>
							</v-list-item-action-text>
						</v-list-item-action>
					</v-list-item>
				</v-list>
			</v-sheet>
		</v-dialog>
		<!-- dialog des historiques -->

		<v-container>
			<div class="d-flex align-center">
				{{ $t('library') }}
				<v-spacer></v-spacer>
				<v-btn large class="yellow--bg border-shaped mb-4" depressed @click="addProduct">
					<v-icon>mdi-plus</v-icon>
					{{ $t('add_product') }}
				</v-btn>
			</div>
			<v-divider class="mb-4"></v-divider>
			<v-data-iterator content-tag="tag" :items="products" :footer-props="footerProps" :items-per-page="itemsPerPage">
				<template #no-data>
					<div class="font-italic text--secondary">{{ $t('no_product') }}</div>
				</template>
				<template #default="{ items }">
					<v-row>
						<v-col cols="12" sm="6" md="4" xl="3" v-for="product in items" :key="product.uuid">
							<v-lazy transition="slide-y-transition">
								<v-card shaped>
									<v-card-title>
										<span v-if="product.title">{{ product.title }}</span>
										<span v-else>Produit n° {{ products.indexOf(product) + 1 }}</span>
										<v-spacer></v-spacer>
										<v-btn icon @click="products.splice(products.indexOf(product), 1)">
											<v-icon>mdi-delete</v-icon>
										</v-btn>
									</v-card-title>
									<ProductForm :product="product"></ProductForm>
									<v-card-actions class="pt-0">
										<v-spacer></v-spacer>
										<OrderProduct :product="product" :orderPoints="orderPoints" class="mr-1"></OrderProduct>
										<v-btn depressed class="yellow--bg border-shaped" @click="product.dialog = true" :disabled="product.dialog">
											<v-icon>mdi-pencil</v-icon>
										</v-btn>
									</v-card-actions>
								</v-card>
							</v-lazy>
						</v-col>
					</v-row>
				</template>
			</v-data-iterator>
			<div class="d-flex align-center mt-4">
				{{ $t('cart') }}
				<v-spacer></v-spacer>
				<v-btn large class="yellow--bg border-shaped mb-4" depressed @click="addOrderPoint">
					<v-icon>mdi-plus</v-icon>
					{{ $t('add_order_point') }}
				</v-btn>
			</div>
			<v-divider class="mb-4"></v-divider>
			<div class="mb-4">
				<span class="text-caption">Options</span>
				<div>
					<v-switch v-model="force_caisse" :label="$t('order.force_caisse')"></v-switch>
				</div>
			</div>
			<v-divider class="mb-4"></v-divider>
			<div>
				<span class="text-caption"> {{ $t('delivery_points') }} </span>
			</div>
			<v-data-iterator content-tag="tag" :items="orderPoints" :items-per-page="-1" hide-default-footer>
				<template #no-data>
					<div class="font-italic text--secondary">{{ $t('no_delivery_points') }}</div>
				</template>
				<template #default="{ items }">
					<v-row>
						<v-col cols="12" v-for="orderPoint in items" :key="orderPoint.uuid">
							<v-card shaped>
								<v-card-title>
									Point de livraison n° {{ orderPoints.indexOf(orderPoint) + 1 }}
									<v-spacer></v-spacer>
									<v-btn icon @click="orderPoints.splice(orderPoints.indexOf(orderPoint), 1)">
										<v-icon>mdi-delete</v-icon>
									</v-btn>
								</v-card-title>
								<OrderPointCard :orderPoint="orderPoint"></OrderPointCard>
							</v-card>
						</v-col>
					</v-row>
				</template>
			</v-data-iterator>
			<div>
				<v-btn
					large
					block
					class="yellow--bg border-shaped mt-4"
					depressed
					@click="calculatePrice"
					:disabled="!isCartSendable || loading"
					:loading="loading"
				>
					<v-icon>mdi-calculator</v-icon>
					{{ $t('calculate') }}
				</v-btn>
			</div>
		</v-container>
	</section>
</template>

<script>
	import axios from 'axios';
	import ProductForm from '@/components/simulation/ProductForm.vue';
	import OrderPointCard from '@/components/simulation/OrderPointCard.vue';
	import OrderProduct from '@/components/simulation/OrderProduct.vue';
	import { nanoid } from 'nanoid';
	import { EventBus } from '@/utils';

	export default {
		name: 'ViewMultiPoint',
		components: { ProductForm, OrderPointCard, OrderProduct },
		props: [],
		data() {
			return {
				dialog: false,
				loading: false,
				prix_emballage: 0,
				prix_fabrication: 0,
				prix_livraison: 0,
				prix_ht: 0,
				prix_ttc: 0,
				force_caisse: false,
				products: JSON.parse(localStorage.getItem('rapidbook-simu-products')) || [],
				orderPoints: [],
				items: [
					{
						text: 'Rapidbook',
						disabled: false,
						to: '/',
					},
					{
						text: 'Dashboard',
						disabled: false,
						to: 'dashboard',
					},
					{
						text: 'Simulation Test',
						disabled: true,
						to: '',
					},
				],
			};
		},
		computed: {
			itemsPerPage() {
				switch (this.$vuetify.breakpoint.name) {
					case 'xl':
						return 4;
					case 'lg':
						return 3;
					case 'md':
						return 3;
					case 'sm':
						return 2;
					default:
						return 1;
				}
			},
			footerProps() {
				return {
					itemsPerPageOptions: [1, 2, 3, 4],
					showFirstLastPage: true,
				};
			},
			isCartSendable() {
				return (
					this.orderPoints.filter(
						(op) => op.lines.length > 0 && (op.address.country.id === 1 ? op.address.code_postal.length >= 5 : true)
					).length > 0
				);
			},
			isForceCaisseAvailable() {
				if (this.panier) {
					const addressQuantities = {};

					this.panier.lignes_commande.forEach((line) => {
						if (line.enabled && line.product.enabled) {
							const address = line.address;
							const quantity = line.quantity;

							if (addressQuantities[address.id]) {
								addressQuantities[address.id] += quantity;
							} else {
								addressQuantities[address.id] = 0;
								addressQuantities[address.id] = quantity;
							}
						}
					});

					// if at least one address have more than 500 quantity
					for (const address in addressQuantities) {
						if (addressQuantities[address] >= 500) {
							return true;
						}
					}
					return false;
				}
				return false;
			},
			orderPointsJSON() {
				return JSON.stringify({
					options: {
						force_caisse: this.force_caisse,
					},
					orderPoints: this.orderPoints.map((op) => {
						return {
							lines: op.lines.map((l) => {
								return {
									product: {
										page_count: l.product.page_count,
										bords_perdus: l.product.bords_perdus,
										category_id: l.product.category?.id,
										finition_id: l.product.finition?.id,
										impression_id: l.product.impression?.id,
										format_id: l.product.format?.id,
										height: l.product.height,
										width: l.product.width,
										papier_couverture_id: l.product.papier_couverture?.id,
										papier_interieur_id: l.product.papier_interieur?.id,
										pelliculage_id: l.product.pelliculage?.id,
										recto_verso: l.product.recto_verso,
										title: l.product.title,
									},
									quantity: l.quantity,
								};
							}),
							address: {
								address_type_id: op.address.address_type.id,
								country_id: op.address.country.id,
								code_postal: op.address.code_postal,
							},
						};
					}),
				});
			},
		},
		watch: {
			products: {
				handler(newValue) {
					localStorage.setItem('rapidbook-simu-products', JSON.stringify(newValue));
				},
				deep: true,
			},
		},
		mounted() {},
		methods: {
			addProduct() {
				const productDefault = {
					bords_perdus: Math.random() < 0.5,
					category: null,
					dialog: true,
					enableCouverture: false,
					enableFormat: false,
					finition: null,
					format: null,
					height: null,
					impression: null,
					page_count: Math.floor(Math.random() * 400) * 2 + 2,
					papier_couverture: null,
					papier_interieur: null,
					pelliculage: null,
					recto_verso: Math.random() < 0.5,
					title: 'Produit n°' + parseInt(this.products.length + 1),
					uuid: nanoid(),
					valid: false,
					width: null,
				};
				this.products.push(productDefault);
			},
			addOrderPoint() {
				const orderPointDefault = {
					uuid: nanoid(),
					lines: [],
					address: {
						address_type: null,
						country: null,
						code_postal: '',
					},
				};
				this.orderPoints.push(orderPointDefault);
			},
			calculatePrice() {
				if (!this.isCartSendable) return;

				this.loading = true;
				axios
					.post(
						process.env.VUE_APP_API_URL + '/simulation-test',
						{
							mode: 'multi',
							jsonData: this.orderPointsJSON,
						},
						{
							headers: {
								Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
							},
						}
					)
					.then((r) => {
						this.prix_fabrication = r.data.prices.prix_fabrication;
						this.prix_livraison = r.data.prices.prix_livraison;
						this.prix_emballage = r.data.prices.prix_emballage;
						this.prix_ht = r.data.prices.prix_ht;
						this.prix_ttc = r.data.prices.prix_ttc;
						this.epaisseur = r.data.prices.epaisseur;
						this.poids = r.data.prices.poids;
						this.dialog = true;
					})
					.catch((e) => {
						EventBus.$emit('notif', this.$t('cannot_calc'), 'error');
						this.errors = e.response.data.errors;
					})
					.finally(() => {
						this.loading = false;
					});
			},
			save() {
				if (!this.isCartSendable) return;

				this.loading = true;
				axios
					.post(
						process.env.VUE_APP_API_URL + '/simulation-test',
						{
							save: true,
							mode: 'multi',
							jsonData: this.orderPointsJSON,
						},
						{
							headers: {
								Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
							},
						}
					)
					.then((r) => {
						this.dialog = false;
						EventBus.$emit('notif', this.$t('line_saved'), 'success');
					})
					.catch((e) => {
						EventBus.$emit('notif', this.$t('cannot_calc'), 'error');
						this.errors = e.response.data.errors;
					})
					.finally(() => {
						this.loading = false;
					});
			},
			getFacture() {
				if (this.isCartSendable) {
					this.errors = null;
					this.loading = true;
					axios
						.post(
							process.env.VUE_APP_API_URL + '/simulation-test',
							{
								facture: true,
								mode: 'multi',
								jsonData: this.orderPointsJSON,
							},
							{
								headers: {
									Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
								},
								responseType: 'blob',
							}
						)
						.then((response) => {
							// Create a blob from the response data
							const blob = new Blob([response.data], { type: 'application/pdf' });

							// Create a link element to trigger the download
							const link = document.createElement('a');
							link.href = window.URL.createObjectURL(blob);
							link.download = 'simulation-' + new Date().toISOString() + '.pdf'; // Set the file name
							link.click();

							// Clean up
							window.URL.revokeObjectURL(link.href);
						})
						.catch((e) => {
							EventBus.$emit('notif', e.response.data.message, 'error');
							this.errors = e.response.data.errors;
						})
						.finally(() => {
							this.loading = false;
						});
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	.simulation {
		min-height: calc(100vh - 64px);
	}
</style>

<i18n lang="json">
{
	"fr": {
		"title": "Simulation Tarifaire",
		"cannot_calc": "Impossible de calculer le prix",
		"history": "Historique",
		"library": "Bibliothèque",
		"no_product": "Aucun produit",
		"add_product": "Ajouter un produit",
		"add_order_point": "Ajouter un point de livraison",
		"cart": "Panier",
		"delivery_points": "Points de livraison",
		"no_delivery_points": "Aucun point de livraison",
		"calculate": "Calculer le prix",
		"line_saved": "Simulation enregistrée",
		"unavailable": "Non disponible"
	},
	"en": {
		"title": "Pricing Simulation",
		"cannot_calc": "Cannot calculate price",
		"history": "History",
		"library": "Library",
		"no_product": "No product",
		"add_product": "Add a product",
		"add_order_point": "Add an order point",
		"cart": "Cart",
		"delivery_points": "Delivery points",
		"no_delivery_points": "No delivery points",
		"calculate": "Calculate the price",
		"line_saved": "Simulation saved",
		"unavailable": "Unavailable"
	}
}
</i18n>
